// Core
import React, { useState, useRef, useEffect } from "react"
import { gsap } from "gsap"
import { isMobile } from "react-device-detect"

// Utils
import { loadAssets, animateSprites } from "../../utils/canvas"

// Components
import SEO from "../../components/seo"
import { drawImages } from "../../components/illustration/images"
import PageComponents from "../../components/pageComponents"

// Style
import sharedStyles from "./../pages.module.scss"

// Assets
import bgImg from "./assets/bg.jpg"
import mgImg from "./assets/mg.png"
import fgImg from "./assets/fg.png"
import nessieImg from "./assets/nessie.png"
import waterImg from "./assets/water-bottom.png"
import cowImg from "./assets/cow.png"
import bridgeImg from "./assets/bridge.png"
import farmbotImg from "./assets/farmbot.png"
import plants1Img from "./assets/plants-1.png"
import plants2Img from "./assets/plants-2.png"
import plants3Img from "./assets/plants-3.png"
import plants4Img from "./assets/plants-4.png"
import plants5Img from "./assets/plants-5.png"
import seedFullImg from "./assets/seed-full.png"
import seedLeftImg from "./assets/seed-left.png"
import seedRightImg from "./assets/seed-right.png"

// Sprites
import seedbotImg from "./assets/sprites/seedbot.png"
import droneImg from "./assets/sprites/drone.png"
import flowerImg from "./assets/sprites/flower.png"

// Audio
import ambient from "./assets/audio/ambient.mp3"
import vo from "./assets/audio/vo.mp3"

const pageData = {
  index: 5,
  preHeading: "Industrial Rewilding",
  heading: "This must be the place where the wild things can roam.",
  content:
    "This is where we tip the scales. Where we mobilise what matters and redraw the map on our past neglect. We don our hardhats and harness technology in harmony with nature. We stop clearing and burning and instead we plant and protect. This is where we welcome back the wilderness to reconnect and rewild. Across our farms and forests, and moors and meadows, we connect the habitats and the hedgerows. Here we gather in the great glens and get to work.",
  slug: "industrial-rewilding",
  nextSlug: "sustainable-industry",
}

const IndustrialRewilding = ({ transitionStatus, entry, exit }) => {
  const [reveal, setReveal] = useState(false)

  // Asset setup
  const bg = useRef({
      parallax: 0.2,
      src: bgImg,
      el: null,
      offset: { x: -50, y: 0 },
    }),
    mg = useRef({
      parallax: 0.5,
      src: mgImg,
      el: null,
      offset: { x: -80, y: 645 },
    }),
    fg = useRef({
      parallax: 2,
      src: fgImg,
      el: null,
      offset: { x: -180, y: 1353 },
    }),
    nessie = useRef({
      parallax: 0.2,
      src: nessieImg,
      el: null,
      offset: { x: 1455, y: 820 },
    }),
    water = useRef({
      parallax: 0.2,
      src: waterImg,
      el: null,
      offset: { x: 1213, y: 790 },
    }),
    cow = useRef({
      parallax: 0.5,
      src: cowImg,
      el: null,
      offset: { x: 1920, y: 810, r: -5 },
      rotationPoint: { x: 92, y: 92 },
      hidden: true,
    }),
    bridge = useRef({
      parallax: 0.5,
      src: bridgeImg,
      el: null,
      offset: { x: 1836, y: 840 },
    }),
    farmbot = useRef({
      parallax: 0.2,
      src: farmbotImg,
      el: null,
      offset: { x: 530, y: 585 },
    }),
    plants1 = useRef({
      parallax: 0.2,
      src: plants1Img,
      el: null,
      offset: { x: 2930, y: 650 },
      hidden: true,
    }),
    plants2 = useRef({
      parallax: 0.2,
      src: plants2Img,
      el: null,
      offset: { x: 2930, y: 640 },
      hidden: true,
    }),
    plants3 = useRef({
      parallax: 0.2,
      src: plants3Img,
      el: null,
      offset: { x: 2938, y: 605 },
      hidden: true,
    }),
    plants4 = useRef({
      parallax: 0.2,
      src: plants4Img,
      el: null,
      offset: { x: 2960, y: 646 },
      hidden: true,
    }),
    plants5 = useRef({
      parallax: 0.2,
      src: plants5Img,
      el: null,
      offset: { x: 2930, y: 690 },
      hidden: true,
    }),
    seedbot = useRef({
      parallax: 0.55,
      src: seedbotImg,
      el: null,
      offset: { x: 4520, y: -500 },
      size: { w: 390, h: 220 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    drone1 = useRef({
      parallax: 0.5,
      src: droneImg,
      el: null,
      offset: { x: 4520, y: -100 },
      size: { w: 270, h: 150 },
      displaySize: { w: 178, h: 100 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    drone2 = useRef({
      parallax: 0.5,
      src: droneImg,
      el: null,
      offset: { x: 5910, y: 300 },
      size: { w: 270, h: 150 },
      currentSprite: 1,
      spriteFrames: 50,
      slowFactor: 1,
      loop: true,
    }),
    // Left
    seedFull2 = useRef({
      parallax: 0.3,
      src: seedFullImg,
      el: null,
      offset: { x: 2940, y: 530, r: 0 },
      displaySize: { w: 30, h: 30 },
      rotationPoint: { x: 7.5, y: 3 },
      hidden: true,
    }),
    seedLeft2 = useRef({
      parallax: 0.25,
      src: seedLeftImg,
      el: null,
      offset: { x: 2980, y: 550, r: 0 },
      displaySize: { w: 24, h: 30 },
      rotationPoint: { x: 6, y: 3 },
      hidden: true,
    }),
    // Center
    seedFull1 = useRef({
      parallax: 0.3,
      src: seedFullImg,
      el: null,
      offset: { x: 3210, y: 420, r: 0 },
      displaySize: { w: 50, h: 50 },
      rotationPoint: { x: 12.5, y: 5 },
      hidden: true,
    }),
    seedLeft1 = useRef({
      parallax: 0.5,
      src: seedLeftImg,
      el: null,
      offset: { x: 3180, y: 470, r: 0 },
      displaySize: { w: 32, h: 40 },
      rotationPoint: { x: 8, y: 4 },
      hidden: true,
    }),
    // Right
    seedFull3 = useRef({
      parallax: 0.4,
      src: seedFullImg,
      el: null,
      offset: { x: 3510, y: 430, r: 0 },
      displaySize: { w: 30, h: 30 },
      rotationPoint: { x: 7.5, y: 3 },
      hidden: true,
    }),
    seedRight1 = useRef({
      parallax: 0.3,
      src: seedRightImg,
      el: null,
      offset: { x: 3550, y: 470, r: 0 },
      displaySize: { w: 24, h: 30 },
      rotationPoint: { x: 6, y: 3 },
      hidden: true,
    }),
    flower1 = useRef({
      parallax: 0.2,
      src: flowerImg,
      el: null,
      offset: { x: 2980, y: 617 },
      size: { w: 60, h: 130 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 4,
      paused: true,
    }),
    flower2 = useRef({
      parallax: 0.2,
      src: flowerImg,
      el: null,
      offset: { x: 3222, y: 700 },
      size: { w: 60, h: 130 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 4,
      paused: true,
    }),
    flower3 = useRef({
      parallax: 0.2,
      src: flowerImg,
      el: null,
      offset: { x: 3577, y: 740 },
      size: { w: 60, h: 130 },
      currentSprite: 1,
      spriteFrames: 10,
      slowFactor: 4,
      paused: true,
    })

  // All assets in this scene (inc. sprites)
  const assets = useRef([
    bg.current,
    nessie.current,
    water.current,
    mg.current,
    cow.current,
    bridge.current,
    farmbot.current,
    plants1.current,
    plants2.current,
    plants3.current,
    plants4.current,
    plants5.current,
    drone1.current,
    drone2.current,
    seedbot.current,
    seedFull1.current,
    seedFull2.current,
    seedFull3.current,
    seedLeft1.current,
    seedLeft2.current,
    seedRight1.current,
    flower1.current,
    flower2.current,
    flower3.current,
    fg.current,
  ])

  // All sprite assets in this scene
  const sprites = useRef([
    seedbot.current,
    drone1.current,
    drone2.current,
    flower1.current,
    flower2.current,
    flower3.current,
  ])

  // Set the mouse entry point if there is one passed in
  const mousePoint = {
    x: entry.state.mousePos ? entry.state.mousePos.x : 0,
    y: entry.state.mousePos ? entry.state.mousePos.y : 0,
  }

  // The point to focus on when the page enters
  const focusPoint = {
    x: 524,
    y: 1222,
  }

  // Anything that should run on each canvas draw
  // (Should always end with drawImages)
  const draw = (cSize, ctx, cursorPos, frameCount) => {
    animateSprites(sprites.current, frameCount)
    drawImages(assets.current, cSize, ctx, cursorPos)
  }

  const seedsTimeline = useRef(gsap.timeline({ repeat: 0, paused: true })),
    seedbotTimeline = useRef(
      gsap.timeline({
        repeat: 0,
        paused: true,
        defaults: { ease: "back.out(1.3)" },
      })
    ),
    drone1Timeline = useRef(
      gsap.timeline({
        repeat: 0,
        paused: true,
        defaults: { ease: "back.out(1)" },
      })
    ),
    drone2Timeline = useRef(
      gsap.timeline({
        repeat: 0,
        paused: true,
        defaults: { ease: "back.out(1)" },
      })
    )

  const showCowTimeout = useRef(null),
    showCowLeftTimeline = useRef(
      gsap.timeline({
        repeat: 0,
        paused: true,
        defaults: { duration: 1, ease: "power4.out" },
      })
    ),
    showCowRightTimeline = useRef(
      gsap.timeline({
        repeat: 0,
        paused: true,
        defaults: { duration: 1, ease: "power4.out" },
      })
    )

  // Initial animations
  const startingAnimations = () => {
    gsap.fromTo(
      farmbot.current.offset,
      { x: 390 },
      { x: 590, duration: 10, ease: "power1.inOut", repeat: -1, yoyo: true }
    )

    // Cow Timelines
    showCowLeftTimeline.current.fromTo(
      cow.current.offset,
      { x: 1960, y: 890, r: -15 },
      {
        x: 1920,
        y: 810,
        r: -5,
        repeat: 1,
        repeatDelay: 1,
        yoyo: true,
        onComplete: hideCow,
      }
    )
    showCowRightTimeline.current.fromTo(
      cow.current.offset,
      { x: 2350, y: 900, r: 45 },
      {
        x: 2395,
        y: 830,
        r: 40,
        repeat: 1,
        repeatDelay: 1,
        yoyo: true,
        onComplete: hideCow,
      }
    )
    showCowTimeout.current = setTimeout(showCow, Math.random() * 5000 + 5000)
  }

  const growPlants = () => {
    botMovements()
  }

  const botMovements = () => {
    seedbotTimeline.current
      .to(seedbot.current.offset, { x: 3070, y: 178, duration: 9 })
      .to(seedbot.current.offset, { x: 1020, duration: 5, ease: "power1.in" })
      .to(
        seedbot.current.offset,
        { y: -500, duration: 2, ease: "power2.in" },
        "-=2"
      )
    drone1Timeline.current
      .to(drone1.current.offset, { x: 2940, y: 400, duration: 8 })
      .to(drone1.current.offset, {
        x: 920,
        duration: 20,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      })
      .to(
        drone1.current.offset,
        { y: 300, duration: 8, ease: "power2.inOut", repeat: -1, yoyo: true },
        "-=2.5"
      )
    drone2Timeline.current
      .to(drone2.current.offset, { x: 3460, y: 300, duration: 8 })
      .to(drone2.current.offset, {
        x: 520,
        duration: 18,
        ease: "power1.inOut",
        repeat: -1,
        yoyo: true,
      })
      .to(
        drone2.current.offset,
        { y: 350, duration: 5, ease: "power2.inOut", repeat: -1, yoyo: true },
        "-=2.5"
      )

    seedbotTimeline.current.play()
    drone1Timeline.current.play()
    drone2Timeline.current.play()

    setTimeout(seedsFalling, 5000)
    setTimeout(plantsAdding, 8200)
  }

  const seedsFalling = () => {
    gsap.fromTo(
      seedFull2.current.offset,
      { r: -10 },
      { r: 10, duration: 1, ease: "power1.inOut", repeat: 6, yoyo: true }
    )
    gsap.to(seedFull2.current.offset, {
      x: 2970,
      y: 730,
      duration: 3.1,
      ease: "none",
      onStart: () => {
        seedFull2.current.hidden = false
      },
      onComplete: () => {
        seedFull2.current.hidden = true
      },
    })
    gsap.fromTo(
      seedLeft2.current.offset,
      { r: -10 },
      {
        r: 10,
        duration: 1,
        ease: "power1.inOut",
        delay: 1.5,
        repeat: 6,
        yoyo: true,
      }
    )
    gsap.to(seedLeft2.current.offset, {
      x: 2930,
      y: 770,
      duration: 3,
      ease: "none",
      delay: 1.5,
      onStart: () => {
        seedLeft2.current.hidden = false
      },
      onComplete: () => {
        seedLeft2.current.hidden = true
      },
    })

    gsap.fromTo(
      seedFull1.current.offset,
      { r: -10 },
      {
        r: 10,
        duration: 1,
        ease: "power1.inOut",
        delay: 0.7,
        repeat: 6,
        yoyo: true,
      }
    )
    gsap.to(seedFull1.current.offset, {
      x: 3200,
      y: 720,
      duration: 3.2,
      ease: "none",
      delay: 0.7,
      onStart: () => {
        seedFull1.current.hidden = false
      },
      onComplete: () => {
        seedFull1.current.hidden = true
      },
    })
    gsap.fromTo(
      seedLeft1.current.offset,
      { r: -10 },
      {
        r: 10,
        duration: 1,
        ease: "power1.inOut",
        delay: 1.9,
        repeat: 6,
        yoyo: true,
      }
    )
    gsap.to(seedLeft1.current.offset, {
      x: 3180,
      y: 690,
      duration: 3,
      ease: "none",
      delay: 1.9,
      onStart: () => {
        seedLeft1.current.hidden = false
      },
      onComplete: () => {
        seedLeft1.current.hidden = true
      },
    })

    gsap.fromTo(
      seedFull3.current.offset,
      { r: -10 },
      {
        r: 10,
        duration: 1,
        ease: "power1.inOut",
        delay: 1,
        repeat: 6,
        yoyo: true,
      }
    )
    gsap.to(seedFull3.current.offset, {
      x: 3500,
      y: 730,
      duration: 2.9,
      ease: "none",
      delay: 1,
      onStart: () => {
        seedFull3.current.hidden = false
      },
      onComplete: () => {
        seedFull3.current.hidden = true
      },
    })
    gsap.fromTo(
      seedRight1.current.offset,
      { r: -10 },
      {
        r: 10,
        duration: 1,
        ease: "power1.inOut",
        delay: 1.9,
        repeat: 6,
        yoyo: true,
      }
    )
    gsap.to(seedRight1.current.offset, {
      x: 3550,
      y: 770,
      duration: 3,
      ease: "none",
      delay: 21.9,
      onStart: () => {
        seedRight1.current.hidden = false
      },
      onComplete: () => {
        seedRight1.current.hidden = true
      },
    })
  }

  const plantsAdding = () => {
    let delay = 300

    plants1.current.hidden = false

    setTimeout(() => {
      plants2.current.hidden = false
      flower3.current.paused = false
    }, delay * 1)

    setTimeout(() => {
      plants3.current.hidden = false
      flower2.current.paused = false
    }, delay * 2)

    setTimeout(() => {
      plants4.current.hidden = false
      flower1.current.paused = false
    }, delay * 3)

    setTimeout(() => {
      plants5.current.hidden = false
    }, delay * 4)
  }

  const showCow = () => {
    cow.current.hidden = false
    if (Math.round(Math.random()) === 0) {
      showCowLeftTimeline.current.play(0)
    } else {
      showCowRightTimeline.current.play(0)
    }
  }

  const hideCow = () => {
    cow.current.hidden = true
    showCowTimeout.current = setTimeout(showCow, Math.random() * 5000 + 5000)
  }

  // Triggered animations
  const showNessie = direction => {
    if (direction === "in" && nessie.current.offset.y === 820) {
      gsap.fromTo(
        nessie.current.offset,
        { y: 820 },
        {
          y: 740,
          duration: 5,
          ease: "power1.inOut",
          onComplete: () => {
            gsap.fromTo(
              nessie.current.offset,
              { y: 740 },
              {
                y: 770,
                duration: 3,
                ease: "power1.inOut",
                repeat: -1,
                yoyo: true,
              }
            )
          },
        }
      )
    }
  }

  // Animate in
  useEffect(() => {
    let revealTimeout = setTimeout(() => {
      setReveal(true)
      startingAnimations()

      if (isMobile) showNessie("in")
    }, 1000)
    let growPlantsTimeout = setTimeout(growPlants, 8000)

    return () => {
      if (revealTimeout) clearTimeout(revealTimeout)
      if (growPlantsTimeout) clearTimeout(growPlantsTimeout)
      if (showCowTimeout.current) clearTimeout(showCowTimeout.current)
    }
  }, [])

  // Load images
  useEffect(() => {
    loadAssets(assets.current)
  }, [])

  return (
    <div>
      <SEO title={pageData.heading} />
      <div className={sharedStyles.page}>
        <PageComponents
          index={pageData.index}
          preHeading={pageData.preHeading}
          heading={pageData.heading}
          content={pageData.content}
          mousePoint={mousePoint}
          focusPoint={focusPoint}
          playing={reveal}
          draw={draw}
          fromPage={pageData.slug}
          to={`/${pageData.nextSlug}`}
          ambient={ambient}
          vo={vo}
          interactiveElements={[
            {
              event: showNessie,
              x: 1000,
              y: 500,
              w: 1000,
              h: 450,
            },
          ]}
        />
      </div>
    </div>
  )
}

export default IndustrialRewilding
